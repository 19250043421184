import React from 'react';
import './App.css';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div>
      <header>
        <h1>Welcome to IT Merchant</h1>
        <p>Your partner in IT talent acquisition and consulting services.</p>
        <nav>
          <a href="#services">Services</a> | <a href="#about">About Us</a> | <a href="#contact">Contact Us</a>
        </nav>
      </header>

      <section id="services">
        <h2>Our Services</h2>
        <ul>
          <li>Permanent IT Hiring</li>
          <li>International Hiring</li>
          <li>Technical Plant Hiring</li>
        </ul>
      </section>

      <section id="about">
        <h2>About IT Merchant</h2>
        <p>We are committed to helping businesses find the best IT talent with speed and efficiency.</p>
      </section>

      <section id="contact">
        <h2>Contact Us</h2>
        <form>
          <input type="text" placeholder="Your Name" required />
          <input type="email" placeholder="Your Email" required />
          <textarea placeholder="Message" required></textarea>
          <button type="submit">Send Message</button>
        </form>
      </section>
    </div>
  );
}

export default App;
